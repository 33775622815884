import React from 'react'
import { Col, Row } from 'react-bootstrap'

function Home() {
    return (  
        <div className="container">
            <div className="home" id="home">
                <Row>
                    <Col className="col-lg-6 col-12 mt-4">
                        <span className="welcome">
                            WELCOME TO MY WORLD
                        </span>
                        <h1 className="text-white mt-3">TMPSoftTech.com</h1>
                        <span className="mt-2 col-4">
                        “If you start out with $100 at the beginning of the year and you were able to increase what you have by 1% every single day, at the end of the year, you would have $3,778.34 = $100 * (1 + 1%) ^ 365. That is 37.78x what you had at the beginning of the year. Get that 1% every single day!”
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Home
