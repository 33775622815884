import brand from './brand.jpg';
import homeimg from './homeimg.png';
import uni from './uni.jpg';
import './App.css';
import {Button, Card, Row, Col, Container, Nav, Navbar, NavDropdown,Form,Image} from 'react-bootstrap'
import { AiFillGithub } from "react-icons/ai";
import { FaFacebookF , FaTwitter , FaInstagram} from "react-icons/fa";
import About from './components/About';
import Home from './components/Home';
import Photo from './components/Photo';

function App() {
  return (
    <div className="App">
        <div className="bg-home">
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container>
                <Navbar.Brand href="#home"><Image src={brand} alt="" className="brand" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#about">About</Nav.Link>
                    <Nav.Link href="#photos">Photos</Nav.Link>
                    <Nav.Link href="#blogs">Blogs</Nav.Link>
   
                </Nav>
                <Nav>
                    <Nav.Link href="https://www.facebook.com/pattawinee.laktong/"><FaFacebookF/></Nav.Link>
                    <Nav.Link href="https://www.instagram.com/pattawinee_tang/"><FaInstagram/></Nav.Link>
                    <Nav.Link href="https://twitter.com/PattawineeT"><FaTwitter/></Nav.Link>
                    <Nav.Link href="https://github.com/Pattawine"><AiFillGithub/></Nav.Link>
                </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>

        <Home/>
        </div>
        <About/>
        <Photo/>
     
    </div>
  );
}

export default App;
