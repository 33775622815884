import React from 'react'
import {Col, Container,Row, Image,Card} from 'react-bootstrap'
function Photo() {
    return (
        <div>
            <div className="photo spacer">
                
                
            </div>
        </div>
    )
}

export default Photo
