import React from 'react'
import shownews from './show_01.png'
import { Col, Row ,Image} from 'react-bootstrap'
function About() {
    return (
        <div className="about spacer" id="about">
            <div className="container">
            <h1 className="text-center">About</h1>
            <p className="text-center">"I am interested in technology related work. And I like business works too. It started with me studying technology since I was a kid. I have few friends. But my friend is a computer that allows me to see the wide world. I graduated with a bachelor of Science (𝙸𝚗𝚏𝚘𝚛𝚖𝚊𝚝𝚒𝚘𝚗 𝚃𝚎𝚌𝚑𝚗𝚘𝚕𝚘𝚐𝚢) degree in university at thailand. And work in programming all the time And recently I decided to study more management. My dream is to build this tech company and this website will be the path for me to document my dream path."</p>     
            </div>
        </div>
     
    )
    
}

export default About


